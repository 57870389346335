import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/home/Hero';
import What from '../components/home/What';
import Why from '../components/home/Why';
import Signup from '../components/home/Signup';
import How from '../components/home/How';
import FAQ from '../components/home/FAQ';
import LoginModal from '../components/forms/LoginModal';
import { segmentTrack } from '../includes/analytics';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

export type IndexProps = {
  data: IndexQueryData
};
export default function Index({ data }:IndexProps) {
  segmentTrack('dasher_application_page');

  const { t } = useTranslation();

  return <Layout>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={t('metadata_description')} />
      <title>{t('metadata_title')}</title>
    </Helmet>
    <LoginModal />
    <Hero
      headline={<>
        <div className="title-row">{t(`Your time.`)}</div>
        <div className="title-row">{t(`Your goals.`)}</div>
        <div className="title-row"><Trans i18nKey="You're the boss.">You're <span style={{ display: "inline-block" }}>the boss.</span></Trans></div>
      </>}
      img={<StaticImage className="img" src="../../images/hero-bg.png" alt={t('dasher making a delivery')} layout="fullWidth" placeholder="none" />}
    />
    <What />
    <Why />
    <Signup />
    <How />
    <FAQ />
  </Layout>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
  locales: {
    edges: [
      node: {
        data: string,
        language: string,
        ns: string,
      }
    ]
  }
};
export const query = graphql`
  query($language: String!) {
    site {
      siteMetadata {
        name
        description
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
